.page {
    width: 100%;
    height: calc(100vh - 102px);
    display: flex;
}

.left {
    position: relative;
    height: 100%;
    flex: 1;
}

.right {
    height: 100%;
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 450px;

    display: flex;
    flex-direction: column;
    padding: 40px;
    background: white;

    overflow-y: auto;
}

.scene_preview {
    width: 80%;
    height: 80%;
    margin: 10vh 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.scene_preview img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: absolute;
}

.preview_spinner{
    z-index: 2;
    position: absolute;
}

.navbar-fluid .navbar {
    margin-bottom: 0px !important;
}

.option_selector_label {
    margin: 25px 0 10px 0;
    font-weight: 600;
    font-size: 12pt;
}

.marginTop {
    margin-top: 50px;
}

.hide_login {
    width: 100px;
    height: 49px;
    z-index: 2;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
}

.copyBtn {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.cropIcon {
    margin-right: 10px;
    filter: invert(1);
}
